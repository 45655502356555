<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-2">
        <v-col cols="auto" class="pr-12">
          <h1>Swim Badge Orders</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Orders"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  class="mr-2"
                  @click="$refs.swimBadgeOrderDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New Swim Badge Order</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="orders"
        class="mt-4"
        no-data-text="No Swim Badge Orders found"
      >
        <template v-slot:item.customer="{ item }">
          <router-link
            :to="{
              name: 'module-arnprior-customers-individual',
              params: { customerId: item.order.customer_id },
            }"
            >{{ item.order.customer.full_name }}</router-link
          >
        </template>
        <template v-slot:item.total="{ item }">
          £{{ item.order.total }}
        </template>
        <template v-slot:item.price="{ item }">
          <div v-for="product in item.order.products" v-bind:key="product.id">
            {{ product.product.price }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.swimBadgeOrderDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <swim-badge-order-dialog ref="swimBadgeOrderDialog" />
  </div>
</template>

<script>
import SwimBadgeOrderDialog from "./components/SwimBadgeOrderDialog";

export default {
  components: {
    SwimBadgeOrderDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Orders",
          disabled: true,
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Customer", value: "customer" },
        { text: "Badge", value: "badge.name" },
        { text: "Child's Name", value: "child_name" },
        { text: "Quantity", value: "quantity" },
        { text: "Total", value: "total" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  methods: {},

  computed: {
    orders() {
      let orders =
        this.$store.getters["arnprior/ordersStore/allSwimBadgeOrders"];

      if (this.searchTerm !== "") {
        orders = orders.filter((o) => {
          const name = o.order.customer.full_name.toLowerCase();
          const email = o.order.customer.email.toLowerCase();
          const badge = o.badge.name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            name.includes(searchTerm) ||
            email.includes(searchTerm) ||
            badge.includes(searchTerm)
          );
        });
      }

      return orders;
    },
  },
};
</script>
